import { render, staticRenderFns } from "./flats-list.vue?vue&type=template&id=48c1fa69"
import script from "./flats-list.vue?vue&type=script&lang=js"
export * from "./flats-list.vue?vue&type=script&lang=js"
import style0 from "./flats-list.vue?vue&type=style&index=0&id=48c1fa69&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports